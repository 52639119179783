const { Component, render, useEffect, useState } = wp.element;

export default function AjaxSearchApp({ selector, plugin_wrapper }) {

  const $ = jQuery;
  let elements = {};

  const [products, setProducts] = useState( [] );
  const [hasBeenPrinted, setHasBeenPrinted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [keyword, setKeyword] = useState('');

  // on component mount
  useEffect(() => {
    // 
    elements.form = $(plugin_wrapper).eq(0);

    //search_for_items();
    setHasBeenPrinted( true );
  }, []);

  const launchSearch = ( e ) => {
    const value = e.target.value;

    if( value.length <= s['min-nb-characters'] ) {
      console.log('%cWooAjaxSearch: not enoght characters', 'color: #212121; font-weight: bold;');

      setProducts( [] );

      return;
    }

    setKeyword( value );
    search_for_items();
  }

  const search_for_items = () => {
    setShowLoader( true );

    jQuery.post( WooAjaxSearch.ajax_url, {
      action: "WooAjaxSearchAjaxRequestNoPriv",
      keyword,
      categ,
      act: 'search'
    }, function(response) {
      setProducts( response.data );
      setShowLoader( false );
    });
  }

  const dHtml = ( code ) => {
     return { __html: code };
  }

  const { settings } = WooAjaxSearch;
  const s = settings; // shortcut ...
  const container_width = s['result-width'];

  let categ = '';
  if( s['show-categ-list'] ){
    categ = $(plugin_wrapper).find("#cat").val();
  }

  const buildProductsList = () => {
    const print_badge = (prod) => {
      if( prod.oos ) return <span className={ `${WooAjaxSearch.alias}badge is-oos` }>{ settings['oos-label'] }</span>
      if( prod.featured ) return <span className={ `${WooAjaxSearch.alias}badge is-featured` }>{ settings['featured-label'] }</span>
      if( prod.sale ) return <span className={ `${WooAjaxSearch.alias}badge is-sale` }>{ settings['sale-label'] }</span>
    }

    return <ul className={ `${WooAjaxSearch.alias}products-list` }>
      {
        products.map( ( product ) => {

          const thumb = (where) => <a href={ product.url } className={ `${WooAjaxSearch.alias}thumbnail ${WooAjaxSearch.alias}${where}` } style={{
              width: `${ settings['thumbnail-width'] }px`,
              height: `${ settings['thumbnail-height'] }px`
            }}>
              <img src={ product.thumb } />
          </a>

          return <li className={ `${WooAjaxSearch.alias}product-item ${WooAjaxSearch.alias}tpl-${WooAjaxSearch.settings['show-thumbnail']}` }>
            { thumb( 'left' ) }
            <div className={ `${WooAjaxSearch.alias}meta` }>
              <a href={ product.url } style={{
                width: `${ container_width - settings['thumbnail-width'] - 30 /* paddings */ }px`
              }}>{print_badge( product )}{ product.name }</a>
              { settings['show-price'] ? <div>
                <span>{ s['show-price-label'] }</span>
                <span dangerouslySetInnerHTML={ dHtml( product.price_html ) } />
              </div> : '' }
              { settings['show-excerpt'] ? <div className={ `${WooAjaxSearch.alias}excerpt` }>{ product.excerpt }</div> : '' }
            </div>
            { thumb( 'right' ) }
          </li>
        })
      }
    </ul>
  }

  const resultsPosition = () => {

    switch( s['custom-position'] ) {
      case 'center':
        return `
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        `;

      case 'right':
        return `
          position: absolute;
          top: 100%;
          right: 0;
        `;
    }
  }

  const showThumbnail = () => {
    if( s['show-thumbnail'] == 'hide' ){
      return `
        .${WooAjaxSearch.alias}product-item {
          grid-template-columns: 1fr;
          grid-column-gap: 0px;
        }
      `;
    }

    if( s['show-thumbnail'] == 'left' ){
      return `
        .${WooAjaxSearch.alias}left {
          display: block !important;
        }
      `;
    }

    if( s['show-thumbnail'] == 'right' ){
      return `
        .${WooAjaxSearch.alias}product-item {
          grid-template-columns: 1fr ${ settings['thumbnail-width'] }px;
          grid-column-gap: 10px;
        }

        .${WooAjaxSearch.alias}right {
          display: block !important;
        }
      `;
    }
  }

  let search_url = `${WooAjaxSearch.search_url}${keyword}&post_type=product`;

  if( categ != 0 ){
    search_url += `&term=${categ}&taxonomy=product_cat`;
  }
  
  return (
    <div className={ `${WooAjaxSearch.alias}ajax-wrapper` }>

      {
        settings['show-categ-list'] === true ? <div className={ `${WooAjaxSearch.alias}categs` } dangerouslySetInnerHTML={{__html: WooAjaxSearch.woo_list_categ }} /> : ''
      }
      
      <div className={ `${WooAjaxSearch.alias}search-wrapper` }>
        <div className={ `${WooAjaxSearch.alias}input-wrapper` }>
          <input type="text" placeholder={ settings['search-input-label'] } onKeyUp={ launchSearch } />
          {
            showLoader ? <img src={ `${WooAjaxSearch.assets_url}/loading.gif` } /> : ''
          }
        </div>
        <a href={ search_url } className={ `${WooAjaxSearch.alias}search-btn` }>{settings['search-label']}</a>
      </div>

      {
        products.length ? buildProductsList() : ''
      }

      <style dangerouslySetInnerHTML={{__html: `
        ${ settings['custom-css'] }

        .${WooAjaxSearch.alias}product-item {
          display: grid;
          grid-template-columns: ${ settings['thumbnail-width'] }px 1fr;
          grid-column-gap: 10px;
        }

        .${WooAjaxSearch.alias}badge.is-oos {
          background-color: ${s['oos-color-normal']};
          font-size: ${s['oos-font-size']}px;
          color: ${s['oos-color-font']}
        }
        .${WooAjaxSearch.alias}badge.is-oos:hover {
          background-color: ${s['oos-color-hover']}
        }

        .${WooAjaxSearch.alias}badge.is-sale {
          background-color: ${s['sale-color-normal']};
          font-size: ${s['sale-font-size']}px;
          color: ${s['sale-color-font']}
        }
        .${WooAjaxSearch.alias}badge.is-sale:hover {
          background-color: ${s['sale-color-hover']}
        }

        .${WooAjaxSearch.alias}badge.is-featured {
          background-color: ${s['featured-color-normal']};
          font-size: ${s['featured-font-size']}px;
          color: ${s['featured-color-font']}
        }
        .${WooAjaxSearch.alias}badge.is-featured:hover {
          background-color: ${s['featured-color-hover']}
        }

        .${WooAjaxSearch.alias}ajax-wrapper {
          ${ s['show-categ-list'] == false ? 'display: block !important;' : ''}
        }
        
        .${WooAjaxSearch.alias}products-list {
          width: ${s['result-width']}px;
          background-color: #fff;
          ${resultsPosition()}
        }

        ${ showThumbnail() }
        
      `}} />
    </div>
  );
}


(function () {
  const replacement_items = [];
  const search_for = [ 'WooAjaxSearch_shortcode', 'WooAjaxSearch_widget' /*, 'woocommerce-product-search'*/ ];

  search_for.forEach( item => {
    const test_elm = document.getElementsByClassName( item );
    if( test_elm[0] ) replacement_items.push( test_elm[0] );
  } )

  if( replacement_items.length == 0 ) console.log('%cNo WooAjaxSearch box on this page ', 'color: #f44336');

  wp.domReady( function() {
    replacement_items.forEach( item => {
      console.log(`%cWooAjaxSearch loader for: ${item.className}`, 'color: #2E7D32')

      render( <AjaxSearchApp plugin_wrapper={ item } />, item )
    })
  })
})()